import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store/store.js'
import { EventBus } from '@/utils/event-bus.js'
const Hongqidai = () =>
  import(/* webpackChunkName: "Hongqidai" */ '@/views/hongqidai.vue')
const UserCenter = () =>
  import(/* webpackChunkName: "UserCenter" */ '@/views/user-center.vue')
const MyHongqidai = () =>
  import(/* webpackChunkName: "MyHongqidai" */ '@/views/my-hongqidai.vue')
const MyAccount = () =>
  import(/* webpackChunkName: "MyAccount" */ '@/views/my-account.vue')
const MyFinancingApply = () =>
  import(
		/* webpackChunkName: "MyFinancingApply" */ '@/views/financing-apply/index.vue'
  )
const DemandRelease = () =>
  import(/* webpackChunkName: "DemandRelease" */ '@/views/demand-release.vue')
const FinancingNews = () =>
  import(/* webpackChunkName: "FinancingNews" */ '@/views/financing-news.vue')
const FinancingNewsList = () =>
  import(
		/* webpackChunkName: "FinancingNews" */ '@/views/financing-news-list.vue'
  )
const NewsDetail = () =>
  import(/* webpackChunkName: "FinancingNews" */ '@/views/news-detail.vue')
const MyDemandRelease = () =>
  import(
		/* webpackChunkName: "DemandRelease" */ '@/views/my-demand-release.vue'
  )
const AntiEpidemicArea = () =>
  import(
		/* webpackChunkName: "AntiEpidemicArea" */ '@/views/anti-epidemic-area/index.vue'
  )
const CulturalCreativeSupport = () =>
  import(
		/* webpackChunkName: "CulturalCreativeSupport" */ '@/views/cultural-creative-support.vue'
  )
const ListingSupportDeclaration = () =>
  import(
		/* webpackChunkName: "ListingSupportDeclaration" */ '@/views/listing-support-declaration.vue'
  )
const SpecialFundsControl = () =>
  import(
		/* webpackChunkName: "SpecialFundsControl" */ '@/views/special-funds-control.vue'
  )
const DevelopmentFundProject = () =>
  import(
		/* webpackChunkName: "DevelopmentFundProject" */ '@/views/development-fund-project.vue'
  )

const TXApply = () =>
  import(/* webpackChunkName: "TXApply" */ '@/views/tx-apply.vue')
const TXCourse = () =>
  import(/* webpackChunkName: "TXCourse" */ '@/views/tx-course.vue')
const CollectTable = () =>
  import(/* webpackChunkName: "CollectTable" */ '@/views/collect-table.vue')

const ServiceGuidance = () =>
  import(
		/* webpackChunkName: "ServiceGuidance" */ '@/views/service-guidance.vue'
  )
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '虹口区融资信用服务平台',
      top: true
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/financing-list',
    name: 'FinancingList',
    meta: {
      title: '虹口区融资信用服务平台-金融产品'
    },
    component: () => import('@/views/financing-list.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: '虹口区融资信用服务平台-金融产品'
    },
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '虹口区融资信用服务平台-虹口介绍'
    },
    component: () => import('@/views/about.vue')
  },
  {
    path: '/user/regist',
    name: 'Regist',
    meta: {
      title: '用户注册—信息核实'
    },
    component: () => import('@/views/regist.vue')
  },
  {
    path: '/user/regist-success',
    name: 'RegistSuccess',
    meta: {
      title: '用户注册—成功'
    },
    component: () => import('@/views/regist_success.vue')
  },
  {
    path: '/reset',
    name: 'resetView',
    meta: {},
    component: () => import('@/views/reset-view.vue')
  },
  {
    path: '/hongqidai',
    name: 'hongqidai',
    meta: {
      title: '虹口区融资信用服务平台-虹企贷'
    },
    component: Hongqidai
  },
  {
    path: '/collect-table',
    name: 'CollectTable',
    meta: {
      title: '虹口区融资信用服务平台-批次担保业务申请表'
    },
    component: CollectTable,
    beforeEnter: (to, from, next) => {
      if (Store.state.isLogin) {
        next()
      } else {
        EventBus.$emit('showLoginModal')
        next('/')
      }
    }
  },
  {
    path: '/tx-apply',
    name: 'TXApply',
    meta: {
      title: '虹口区融资信用服务平台-贴息申请'
    },
    component: TXApply,
    beforeEnter: (to, from, next) => {
      if (Store.state.isLogin) {
        next()
      } else {
        EventBus.$emit('showLoginModal')
        next('/')
      }
    }
  },
  {
    path: '/tx-course',
    name: 'TXCourse',
    meta: {
      title: '虹口区融资信用服务平台-贴息申请'
    },
    component: TXCourse
  },
  {
    path: '/demand-release',
    name: 'demandRelease',
    meta: {
      title: '虹口区融资信用服务平台-需求发布'
    },
    beforeEnter: (to, from, next) => {
      if (Store.state.isLogin) {
        next()
      } else {
        EventBus.$emit('showLoginModal')
        next('/')
      }
    },
    component: DemandRelease
  },
  {
    path: '/financing-news',
    name: 'financingNews',
    meta: {
      title: '虹口区融资信用服务平台-融资资讯'
    },
    component: FinancingNews
  },
  {
    path: '/service-guidance',
    name: 'ServiceGuidance',
    meta: {
      title: '虹口区融资信用服务平台-服务业发展引导资金申报通知'
    },
    component: ServiceGuidance
  },
  {
    path: '/financing-news-list',
    name: 'financingNewsList',
    meta: {
      title: '虹口区融资信用服务平台-融资资讯'
    },
    component: FinancingNewsList
  },
  {
    path: '/news-detail/:id',
    name: 'NnewsDetail',
    meta: {
      title: '虹口区融资信用服务平台-融资资讯'
    },
    component: NewsDetail
  },
  {
    path: '/anti-epidemic-area',
    name: 'AntiEpidemicArea',
    meta: {
      title: '虹口区融资信用服务平台-高质量发展贴息补助专区'
    },
    component: AntiEpidemicArea
  },
  {
    path: '/user-center',
    redirect: { name: 'MyAccount' }
  },
  {
    path: '/user-center',
    name: 'userCenter',
    meta: {
      title: '虹口区融资信用服务平台-用户中心'
    },
    component: UserCenter,
    beforeEnter: (to, from, next) => {
      console.log(to, from, next)
      if (Store.state.isLogin) {
        next()
      } else {
        next('/')
      }
    },
    children: [
      {
        path: 'my-hongqidai',
        name: 'MyHongqidai',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: MyHongqidai
      },
      {
        path: 'my-account',
        name: 'MyAccount',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: MyAccount
      },
      {
        path: 'financing-apply',
        name: 'MyFinancingApply',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: MyFinancingApply
      },
      {
        path: 'hkmd-apply',
        name: 'MyFinancingApply',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: ()=>import("@/views/mine/hkmd-apply/index.vue")
      },
      {
        path: 'my-demand-release',
        name: 'MyDemandRelease',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: MyDemandRelease
      },
      {
        path: 'company-add',
        name: 'CompanyAdd',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: () => import('@/views/backend/company-add.vue')
      },
      {
        path: 'my-company',
        name: 'MyCompany',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },

        component: () => import('@/views/backend/my-company.vue')
      },
      {
        path: 'auth-company',
        name: 'AuthCompany',
        redirect:'/user-center/auth-company/index',
        meta: {
          title: '虹口区融资信用服务平台-用户中心'
        },
        component: () => import('@/views/mine/AuthCompany/index.vue'),
        children: [
          {
            path: 'index',
            name: 'AuthCompanyIndex',
            meta: {
              title: '虹口区融资信用服务平台-用户中心',
            },
            component: () => import('@/views/mine/AuthCompany/Index/index.vue'),
          },
          {
            path: 'add',
            name: 'AddAuthCompany',
            meta: {
              title: '虹口区融资信用服务平台-用户中心',
            },
            component: () => import('@/views/mine/AuthCompany/Add/index.vue'),
          },
        ]
      }
    ]
  },
  {
    path: '/cultural-creative-support',
    name: 'CulturalCreativeSupport',
    meta: {
      title: '虹口区文化创意产业专项扶持资金页面'
    },
    component: CulturalCreativeSupport
  },
  {
    path: '/listing-support-declaration',
    name: 'ListingSupportDeclaration',
    meta: {
      title: '虹口区企业改制上市扶持申报页面'
    },
    component: ListingSupportDeclaration
  },
  {
    path: '/special-funds-control',
    name: 'SpecialFundsControl',
    meta: {
      title: '虹口区现代商贸业发展专项资金页面'
    },
    component: SpecialFundsControl
  },
  {
    path: '/development-fund-project',
    name: 'DevelopmentFundProject',
    meta: {
      title: '张江国家自主创新示范区专项发展资金重点项目页面'
    },
    component: DevelopmentFundProject
  },
  {
    path: '/feature/hk-area',
    name: 'HKArea',
    meta: {
      title: '虹口区-重点园区专区',
      headerBgColor: 'transparent',
    },
    component: () => import('@/views/hk-area/index.vue'),
  },
]

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior: () => ({ y: 0 })
})

router.afterEach((to, from) => {
  const { title = null } = to.meta || {}
  console.log(to)
  title && (document.title = title)
})

export default router
